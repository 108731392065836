<template>
     <v-text-field :filled="isFilled" ref="myInput" :value="value" v-on:keyup.enter="enter" @input="handleInput()" :counter="max" :label="label" :name="name" :rules="formRules"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"  outlined dense rounded
            :prepend-inner-icon="icon" color="#00A551"
          ></v-text-field>
</template>
<script>
    export default {
        data() {
            return {
                show1: false,
                 formRules: [],
                 content:this.value
            };
        },
        methods: {
            setFormRules() {
                let validate = this.validation; 
                let tmp_validate = [];                
                validate.forEach(rule => {
                    switch (rule) {
                        case 'required':
                            tmp_validate.push(v => !!v || 'Tidak Boleh Kosong');
                        break;
                         case 'email':
                            tmp_validate.push(v => /.+@.+\..+/.test(v) || 'Format E-mail Salah');
                        break
                    }
                });

                if (this.max) {
                    tmp_validate.push(v => !!v && v.length <= this.max || "Maksimal "+this.max+" Karakter");
                }
                if (this.min) {
                    tmp_validate.push(v => !!v && v.length >= this.min || "Minimal "+this.min+" Karakter");
                }

                 this.formRules = tmp_validate;
            },
            handleInput (e) {
                this.$emit('input', this.$refs.myInput.value)
            },
             enter() {
                this.$emit('enter');
            },
        },
        mounted() {
           this.setFormRules();
        },
        props: ['label', 'name', 'value','validation','max','min','icon','isFilled'],
        name: 'formInput',
    }
</script>